/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect Mobile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Frequency
 */
export interface Frequency {
    /**
     * 
     * @type {string}
     * @memberof Frequency
     */
    frequency_type: FrequencyFrequencyTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Frequency
     */
    frequency_value: number;
    /**
     * 
     * @type {string}
     * @memberof Frequency
     */
    type: FrequencyTypeEnum;
}


/**
 * @export
 */
export const FrequencyFrequencyTypeEnum = {
    Day: 'day',
    Month: 'month',
    Year: 'year'
} as const;
export type FrequencyFrequencyTypeEnum = typeof FrequencyFrequencyTypeEnum[keyof typeof FrequencyFrequencyTypeEnum];

/**
 * @export
 */
export const FrequencyTypeEnum = {
    Frequency: 'frequency'
} as const;
export type FrequencyTypeEnum = typeof FrequencyTypeEnum[keyof typeof FrequencyTypeEnum];


/**
 * Check if a given object implements the Frequency interface.
 */
export function instanceOfFrequency(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "frequency_type" in value;
    isInstance = isInstance && "frequency_value" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function FrequencyFromJSON(json: any): Frequency {
    return FrequencyFromJSONTyped(json, false);
}

export function FrequencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Frequency {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'frequency_type': json['frequency_type'],
        'frequency_value': json['frequency_value'],
        'type': json['type'],
    };
}

export function FrequencyToJSON(value?: Frequency | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'frequency_type': value.frequency_type,
        'frequency_value': value.frequency_value,
        'type': value.type,
    };
}

