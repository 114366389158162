import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { List } from 'semantic-ui-react';
import { ReportDefect } from '../../../../../backend_api/models';
import { SeverityLabel } from '../../../../globals/components/Severity';
import { getReportDefectsSummarySelector } from '../../../selectors/reportSelectors';
import { getDefectType } from '../DefectsItem';
import ItemsAffected from '../ItemsAffected';

const DefectsSummary = (): React.ReactElement => {
    const data = useSelector(getReportDefectsSummarySelector);
    if (data && data.length > 0) {
        return <div className='defects-summary'>
            <h3><FormattedMessage id='defects.summary.general_heading' /></h3>
            {data.map((defect: ReportDefect, i: number) => {
                const link = '#defect_' + defect.id;
                return <List.Description className='defect items-center' key={'defectItem_' + i}><SeverityLabel className='text-xs py-0 px-1 w-auto' severity={defect.severity} />                    
                    {/*  Fixme: Using href to properly link to anchor - React Router v5 has issues linking to anchors using Link */}
                    <a href={link} className='link noUnderline noColor'>
                        {getDefectType(defect.type)}
                        <ItemsAffected pcs={defect.quantity_affected} all={defect.whole_lot} doNotShowAll={true} />
                    </a>
                </List.Description>
            })}
        </div>
    }
    return null;
}

export default DefectsSummary;
