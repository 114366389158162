/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Translations } from './Translations';

export type TextWithTranslation = {
    language: string | null;
    text: string;
    translation_method?: TextWithTranslation.translation_method | null;
    translations: Translations;
};

export namespace TextWithTranslation {

    export enum translation_method {
        MANUEL = 'manuel',
        MANUAL = 'manual',
        AUTOMATIC = 'automatic',
    }


}

