import { format } from "date-fns";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Modal, Popup } from "semantic-ui-react";
import { Audit } from "../../../../backend_api/models";
import Button from "../../../../base/components/basic/Button";
import { Radio } from "../../../../base/components/basic/CheckboxRadio";
import DatePicker3 from "../../../../base/components/basic/DatePicker3";
import Icon from "../../../../base/components/basic/Icon";
import Input from "../../../../base/components/basic/Input";
import Label from "../../../../base/components/basic/Label";
import FormField from "../../../../base/components/basic/form/FormField";
import { preventDefaultAndStopPropagation, validateEmail } from "../../../../base/utils";
import { useAppDispatch } from "../../../../store";
import UsersSelector from "../../../users/components/UsersSelector";
import { getUsersById } from "../../../users/selectors";
import { updateAudit, updateAuditAuditor } from "../../slices/auditSlice";
import { SendEmailPreviewModal } from "../edit-audit/SendEmailPreviewModal";

export function ReassignAuditModal(props: {
    audit: Audit
}) {
    const { audit } = props;
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const enterEmailTranslation = intl.formatMessage({ id: 'audits.edit.enter_email_address' });

    const users = useSelector(getUsersById);
    const [assignedUser, setAssignedUser] = React.useState(audit?.assigned_user);
    const isAssignedUserExternal = assignedUser?.id && !users[assignedUser?.id];

    const [assigningToExternalUser, setAssigningToExternalUser] = React.useState(isAssignedUserExternal);
    const [showAssignModal, setShowReassignModal] = React.useState(false);
    const [showPreviewEmailModal, setShowPreviewEmailModal] = React.useState(false);

    const [externalEmail, setExternalEmail] = React.useState(audit?.assigned_user?.email);
    const isValidEmail = externalEmail && validateEmail(externalEmail);
    const [showEmailError, setShowEmailError] = React.useState(false);

    function doUpdateAudit(audit: Audit) {
        dispatch(updateAudit({ audit }));
    }

    function onAuditDateChanged(d: Date) {
        if (d) {
            const newDate = format(d, 'yyyy-MM-dd');
            doUpdateAudit({
                ...audit,
                planned_date: new Date(newDate)
            })
        } else {
            doUpdateAudit({
                ...audit,
                planned_date: null
            })
        }
    }

    function reassignToExistingUser() {
        dispatch(updateAuditAuditor({ audit_id: audit.id, user_id: assignedUser.id }))
    }

    const assignExistingUser = <div>
        <FormField>
            <div className='flex flex-row justify-between items-center w-full sm:w-2/3 mb-1'>
                <label className='text-sm text-secondary'><FormattedMessage id='audits.edit.assignee' /></label>
            </div>
            <UsersSelector
                className='py-1 w-full sm:w-2/3'
                disabled={false}
                users={users}
                placeholder={intl.formatMessage({ id: 'audits.select_inspector' })}
                showUnassigned
                showExpanded
                showEmailInSelector
                allowMultipleSelection={false}
                dataTestId={'audits-add-inspector'}
                value={[assignedUser?.id]}
                fluid
                handleChange={({ value }): void => {
                    setAssignedUser(users[value] || audit.assigned_user)
                }} />
        </FormField>
        <FormField className='mt-4'>
            <div className='flex flex-row justify-between items-center w-full sm:w-2/3 mb-1'>
                <label className='text-sm text-secondary'><FormattedMessage id='audits.edit.date' /></label>
            </div>
            <div className='py-1 w-full sm:w-2/3'>
                <DatePicker3
                    date={audit?.planned_date || null}
                    handleChange={onAuditDateChanged}
                    pastDates={true}
                    name={'planned_date'}
                    disabled={false}
                />
            </div>
        </FormField>
    </div>
    const assignExternalUser = <div>
        <FormField>
            <div className='flex flex-row justify-between items-center w-full sm:w-2/3 mb-1'>
                <label className='text-sm text-secondary'><FormattedMessage id='email' /></label>
            </div>
            {
                showEmailError && !isValidEmail &&
                <div className='flex flex-row inline text-sm text-alert mb-1'>
                    <Icon name='error' />
                    <span className='pl-1'><FormattedMessage id='audits.edit.warning.invalid_email' /></span>
                </div>
            }
            <div className='w-full sm:w-2/3 pt-1'>
                <Input
                    type="text"
                    name="email"
                    onChange={(e) => setExternalEmail(e.target.value)}
                    onBlur={(e) => {
                        setShowEmailError(!validateEmail(e.target.value));
                    }}
                    value={externalEmail}

                    iconPosition="left"
                    iconClass="text-brand w-12 justify-center flex "
                    placeholder={enterEmailTranslation}
                    disabled={false}
                />
            </div>
        </FormField>
    </div>

    return (
        <div onClick={preventDefaultAndStopPropagation} key={audit.id}>
            <Modal
                open={showAssignModal}
                closeOnDimmerClick
                closeOnEscape
                size='small'
                trigger={
                    <Popup trigger={
                        <Label small className='text-primary bg-surface-plain border-default whitespace-pre' onClick={(e) => {
                            preventDefaultAndStopPropagation(e);
                            setShowReassignModal(true);
                        }}>
                            <span className={'flex justify-center px-2'}>
                                <Icon name='forward_to_inbox' className='text-lg' />
                            </span>
                        </Label>
                    } hoverable>
                        <div className='text-base font-bold'>{[assignedUser.firstname || '-', ' ', assignedUser.lastname || '-']}</div>
                        <div className='text-base'>{audit?.assigned_user?.email}</div>
                    </Popup>
                }>
                <Modal.Header>Reassign audit</Modal.Header>
                <Modal.Content>
                    <div className="assignee-and-date bg-white rounded">
                        <div className='text-lg font-bold mb-4'><FormattedMessage id='audits.edit.assignee_and_date' /></div>
                        <div className='mb-4'>
                            <div className={'flex flex-row px-1 py-2 items-center w-full sm:w-2/3 cursor-pointer'} onClick={() => setAssigningToExternalUser(false)}>
                                <div className='flex justify-start items-center bg-white' >
                                    <Radio checked={!assigningToExternalUser} onChange={(e) => {
                                        setAssigningToExternalUser(false);
                                    }} />
                                </div>
                                <div className='flex flex-col px-3 justify-start items-start'>
                                    <FormattedMessage id="audits.edit.existing_user" />
                                </div>
                            </div>
                            <div className={'flex flex-row px-1 py-2 items-center w-full sm:w-2/3 cursor-pointer'} onClick={() => setAssigningToExternalUser(true)}>
                                <div className='flex justify-start items-center bg-white' >
                                    <Radio checked={assigningToExternalUser} onChange={(e) => {
                                        setAssigningToExternalUser(true);
                                    }} />
                                </div>
                                <div className='flex flex-col px-3 justify-start items-start'>
                                    <div className='pt-1'><FormattedMessage id="audits.edit.external_user" /></div>
                                    <div className='text-sm text-secondary'><FormattedMessage id="audits.edit.external_user.description" /></div>
                                </div>
                            </div>
                        </div>
                        {
                            assigningToExternalUser ? assignExternalUser : assignExistingUser
                        }
                    </div>
                </Modal.Content>
                <Modal.Actions className='flex flex-row justify-end my-2'>
                    {
                        assigningToExternalUser ?
                            <Button className='' primary={externalEmail && externalEmail != assignedUser?.email} disabled={externalEmail == assignedUser?.email} onClick={() => setShowPreviewEmailModal(true)}><FormattedMessage id='audits.edit.preview_and_send_email' /></Button> :
                            <Button className='' primary onClick={reassignToExistingUser}><FormattedMessage id='audits.edit.reassign_audit' /></Button>
                    }
                    <Button className='' onClick={() => setShowReassignModal(false)}><FormattedMessage id='globals.confirmation.cancel' /></Button>
                </Modal.Actions>
            </Modal>

            <SendEmailPreviewModal
                showing={showPreviewEmailModal}
                email={externalEmail}
                audit={audit}
                closeModal={() => setShowPreviewEmailModal(false)}
                isResending={false}
            />
        </div>
    )
}