import React from 'react';
import { FormattedMessage } from 'react-intl';
import Link from '../../../base/components/basic/Link';


const Footer = (): React.ReactElement => {
    const cl = 'link noUnderline whitespace-nowrap';
    return (
        <div className='footer hideOnPrint'>

            <div><FormattedMessage id='header.footer.qarma_simplify_quality_control_and_make_better_products' /></div>
            <div>Hermodsvej 5B, 3., 8230 Åbyhøj, Denmark</div>
            <div className='flex justify-center space-x-4 py-2 flex-col sm:flex-row'>
                <div><a className={cl} href='http://support.qarmainspect.com'><FormattedMessage id='header.footer.contact_support' /></a></div>
                <div><Link className={cl} to='/get_app'><FormattedMessage id='header.footer.download_app' /></Link></div>
                <div><Link className={cl} to='/training_videos'><FormattedMessage id='header.footer.training_videos' /></Link></div>
                <div><a className='link noUnderline whitespace-nowrap' href='https://status.qarmainspect.com'><FormattedMessage id='footer.status' /></a></div>
                {/* <div className='link noUnderline h-6' id="noticeable-widget"><FormattedMessage id='footer.product_updates' /></div> */}
                <div><a className={cl} href='http://updates.qarmainspect.com' target='_blank'><FormattedMessage id='footer.product_updates' /></a></div>

            </div>
            <div className='pb-2'>© {new Date().getUTCFullYear()} Qarma</div>
            <div><a className='link noUnderline' href='http://beian.miit.gov.cn/'><FormattedMessage id='header.footer_icp' /></a> - <a className='link noUnderline' href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602005803'><FormattedMessage id='header.footer_pcb' /></a></div>
        </div>);
};

export default Footer;
