import { format } from 'date-fns';
import dotProp from 'dot-prop';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Audit, AuditFeaturesEnum } from '../../../backend_api/models/Audit';
import Loader from '../../../base/components/Loader';
import DatePicker from '../../../base/components/basic/DatePicker3';
import { DEFAULT_DATE_FORMAT2, LIMITED_SELECTOR_ITEMS_COUNT } from '../../../base/config';
import { getFeaturesSelector } from '../../../base/selectors';
import { deepCopy, getPrettyDate, isUndefinedOrNull, preventDefaultAndStopPropagation, twMerge, urlParamsToArray } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { getAuditChecklists } from '../../checklists/actions/actions';
import AuditChecklistsSelector from '../../checklists/components/AuditChecklistsSelector';
import { getAuditChecklistsSelector } from '../../checklists/selectors';
import { ListView } from '../../globals/components';
import { InfoIcon } from '../../globals/components/Utils';
import { ListViewHeader, ListViewItem, ListViewLine } from '../../globals/components/views/list/ListView';
import UsersSelector from '../../users/components/UsersSelector';
import UserInitials from '../../users/components/Widgets';
import { getProfileSelector, getUsersById } from '../../users/selectors';
import { getAuditListSelector } from '../selectors/auditSelectors';
import { getAuditList, updateAudit, updateAuditAuditor } from '../slices/auditSlice';
import { getAuditDate } from '../util';
import AuditCorrectiveActionStatusBar from './AuditCorrectiveActionStatusPie';
import AuditStatus, { default as AuditStatusComponent } from './AuditStatus';
import AuditTypeTag from './AuditTypeTag';
import { ExternalUserIcon } from './ExternalUserIcon';
import { SelfAssignedAuditPopup } from './SelfAssignedAuditPopup';
import UnlockAudit from './ReassignAuditPopup';
import { SendEmailPreviewModal } from './edit-audit/SendEmailPreviewModal';
import ReassignAuditPopup from './ReassignAuditPopup';

type AuditListProps = {
    supplierId?: string;
    className?: string;
    audits?: Audit[];
    isLoading?: boolean;
    onUpdate?();
};

const BasicAuditList = (props: AuditListProps): React.ReactElement => {
    const { onUpdate, className, isLoading } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const formatMessage = intl.formatMessage;
    const isGlobal = !props.supplierId;
    const auditListData = !isUndefinedOrNull(props.audits) ? props.audits : useSelector(getAuditListSelector);
    const auditChecklists = useSelector(getAuditChecklistsSelector);
    const hasChecklists = auditChecklists.length > 0;
    const [users] = useState(useSelector(getUsersById));

    const [auditList, setAuditList] = useState<Audit[]>(auditListData);
    const loggedInUserId = useSelector(getProfileSelector).profile.id;
    const features = useSelector(getFeaturesSelector);

    const [reassigningAuditId, setReassigningAuditId] = useState(undefined);
    const [showingSelfAssignedAudit, setShowingSelfAssignedAudit] = useState(null);
    const [auditWithExternalUser, setAuditWithExternalUser] = useState<Audit>(null);
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });

    const onSelfAssignedAuditClick = (e, audit: Audit) => {
        setPopupPosition({
            x: e.clientX,
            y: e.clientY
        })
        setShowingSelfAssignedAudit(audit);
    }

    const update = (items: any[], index: number, save = true): void => {
        const newData = deepCopy(auditList);
        const obj = newData[index];

        items.forEach((item: any) => {
            dotProp.set(obj, item.name, item.value);
        });

        newData[index] = obj;
        setAuditList(newData);
        if (save) {
            dispatch(updateAudit({ audit: obj }));
            onUpdate && onUpdate();
        }
    };

    function updateChecklist(checklist_id: string | null, index: number) {
        const auditListCopy = deepCopy(auditList)
        const auditCopy = auditListCopy[index]
        auditCopy.checklist_id = checklist_id
        setAuditList(auditListCopy)
        dispatch(updateAudit({ audit: auditCopy }))
    }

    const getDate = (audit: Audit, i: number, disabled: boolean): React.ReactElement => {
        const date = getAuditDate(audit);
        const isReport = audit.status === 'report' || audit.status === 'ongoing';
        return !isReport ?
            <span onClick={(e) => preventDefaultAndStopPropagation(e)}><DatePicker
                date={date}
                handleChange={(d): void => {
                    update([{ name: 'planned_date', value: d ? format(d, DEFAULT_DATE_FORMAT2) : null }], i);
                }}
                placeholderKey='audits.list.select_date'
                pastDates={true}
                name={'planned_date'}
                disabled={disabled} /></span>

            :
            <div className='flex w-full'>{getPrettyDate(date)}</div>

    };
    const getInspector = (audit: Audit, i: number, canEdit?: boolean): React.ReactElement => {
        const disabled = audit.locked || audit.status === 'report' || !canEdit;
        const isAssignedUserExternal = audit?.assigned_user?.id && !users[audit?.assigned_user?.id];
        const insp = <span onClick={preventDefaultAndStopPropagation}>
            {disabled && <UserInitials.UserInitials disabled user={audit.assigned_user} showInfo />}
            {reassigningAuditId != audit.id && !disabled &&
                <span onClick={(evt): void => {
                    preventDefaultAndStopPropagation(evt);
                    setReassigningAuditId(audit.id);
                }}><UserInitials.UserInitials user={audit.assigned_user} showInfo={true} /></span>
            }
            {reassigningAuditId === audit.id && <UsersSelector open={true} showUnassigned disabled={disabled} showExpanded users={users} value={[audit.assigned_user && audit.assigned_user.id]} placeholder={intl.formatMessage({ id: 'audits.select_user' })} fluid={true} allowMultipleSelection={false}
                handleChange={({ value }): void => {
                    dispatch(updateAuditAuditor({ audit_id: audit.id, user_id: value }))
                    setReassigningAuditId(undefined);
                }} />}
        </span>
        if (isAssignedUserExternal) {
            return <ExternalUserIcon audit={audit} onResendEmail={setAuditWithExternalUser} />
        }

        if (audit.locked && (audit.status == 'ongoing' || audit.status == 'planned')) {
            return <ReassignAuditPopup
                audit={audit}
                key={'audit_item_' + i}
            >{insp}</ReassignAuditPopup>
        }
        return insp;
    };
    const headerItems = [
        { label: undefined, className: 'sm:w-1/24', key: 'audit_type' },
        { label: formatMessage({ id: 'audit_list.header.supplier' }), className: 'sm:w-4/24', key: 'supplier' },
        { label: formatMessage({ id: 'audit_list.header.production_unit' }), className: 'sm:w-4/24', key: 'production_unit' },
        { label: formatMessage({ id: 'audit_list.header.inspector' }), className: 'sm:w-2/24', key: 'inspector' },
        { label: formatMessage({ id: 'audit_list.header.date' }), className: 'w-3/16 sm:w-4/24', key: 'date' },
        { label: formatMessage({ id: 'audit_list.header.checklist' }), className: 'sm:w-5/24', key: 'checklist' },
        { label: formatMessage({ id: 'audit_list.header.ca' }), className: 'sm:w-2/24', key: 'ca' },
        { label: formatMessage({ id: 'audit_list.header.status' }), className: 'sm:w-3/24', key: 'status' },
    ];

    useEffect(() => {
        !props.audits && dispatch(getAuditChecklists());
    }, []);
    useEffect(() => {
        !props.audits && dispatch(getAuditList({ supplierId: props.supplierId, filters: urlParamsToArray(location.search), limit: LIMITED_SELECTOR_ITEMS_COUNT, offset: 0 }));
    }, [location.search]);
    useEffect(() => {
        setAuditList(auditListData);
    }, [auditListData]);

    function getRedirectLink(audit: Audit): string {
        const downloaded = audit.locked && audit.status === 'planned';
        const canEdit = audit.features.includes(AuditFeaturesEnum.Edit);
        return !downloaded && (audit.status !== 'planned' ? '/audits/report/' + audit.id : canEdit ? '/audits/edit_audit/' + audit.id : null);
    }

    return (
        <Fragment>
            {auditList && <>
                {auditList.length > 0 && <ListViewHeader items={headerItems} />}
                <Loader active={isLoading}>
                    {auditList.length === 0 && <div className={twMerge('justify-center flex p-4')}><InfoIcon compact text={formatMessage({ id: 'audits.no_audits_found' })} /></div>}
                    {auditList.length > 0 && <ListView className={twMerge('', className)} >
                        {auditList.map((audit, i) => {
                            const isReport = audit.status === 'report' || audit.status === 'ongoing';
                            const canEdit = audit.features.includes(AuditFeaturesEnum.Edit);
                            const disabled = audit.locked || audit.status === 'ongoing' || audit.status === 'report' || !canEdit;
                            const downloaded = audit.locked && audit.status === 'planned';
                            const plannedAndAssigned = audit.status === 'planned' && audit.assigned_user?.id == loggedInUserId;
                            const inProgressAndAssigned = audit.status === 'ongoing' && audit.assigned_user?.id == loggedInUserId;
                            const canAccessWebBasedAudit = (plannedAndAssigned || inProgressAndAssigned) && !!audit?.checklist_id;

                            const checklistSelector = <AuditChecklistsSelector
                                key={'chl_' + i}
                                checklists={auditChecklists}
                                checklistId={audit.checklist_id}
                                onChecklistIdChanged={(checklistId) => updateChecklist(checklistId, i)}
                                disabled={disabled || !hasChecklists}
                                showUnassigned={true}
                                stopPropagation={true}
                                className='w-full'
                            />;

                            return <ListViewLine key={'audit_' + audit.id}
                                onClick={(e) => canAccessWebBasedAudit && onSelfAssignedAuditClick(e, audit)}
                                link={canAccessWebBasedAudit ? '' : getRedirectLink(audit)}
                                className={twMerge('auditLine', isGlobal ? 'pl-8' : 'pl-2', 'pr-8 py-4 text-primary hover:text-primary hover:bg-highlight-green', !downloaded && canEdit && 'cursor-pointer')}>
                                <ListViewItem className='order-1 sm:w-1/24'><AuditTypeTag auditStatus={audit.status} auditType={audit.audit_type} /></ListViewItem>
                                <ListViewItem className='order-1 sm:w-4/24'>
                                    {audit.target_supplier && audit.target_supplier.name || '-'}
                                </ListViewItem>
                                <ListViewItem className='productionUnit order-2 sm:w-4/24 w-full'>
                                    {audit.target_production_unit && audit.target_production_unit.name || <FormattedMessage id='audits.list.production_unit_not_set' />}
                                </ListViewItem>
                                <ListViewItem className='inspector order-4 w-2/12 sm:w-2/24' onClick={e => e.stopPropagation()}>
                                    {getInspector(audit, i, canEdit)}
                                </ListViewItem>
                                <ListViewItem className='date order-2 w-full sm:w-4/24 sm:order-4 sm:mr-1'>{getDate(audit, i, disabled)}</ListViewItem>
                                <ListViewItem className='order-3 sm:order-4 w-full sm:w-5/24 sm:pr-3 shrink-0'>
                                    {/* TODO: FIXME: hack to make sure checklists with long names are not overflowing the container */}
                                    {!isReport && <div className='w-full'>
                                        {checklistSelector}
                                    </div>}
                                    {isReport && <div className='w-full'>
                                        {audit.checklist_name || '-'}
                                    </div>}
                                </ListViewItem>
                                <ListViewItem className='status order-6 sm:w-2/24 justify-left'>
                                    <AuditCorrectiveActionStatusBar auditId={audit.id} correctiveActionStatus={audit.corrective_actions_status} />
                                </ListViewItem>
                                <ListViewItem className='status order-6 sm:w-3/24'>
                                    <AuditStatusComponent audit={audit} />
                                </ListViewItem>
                            </ListViewLine>
                        })}
                    </ListView>
                    }
                    {showingSelfAssignedAudit &&
                        <SelfAssignedAuditPopup
                            x={popupPosition.x}
                            y={popupPosition.y}
                            close={() => setShowingSelfAssignedAudit(null)}
                            audit={showingSelfAssignedAudit}
                        />
                    }
                    <SendEmailPreviewModal
                        showing={!!auditWithExternalUser}
                        audit={auditWithExternalUser}
                        closeModal={() => setAuditWithExternalUser(null)}
                        isResending={true}
                    />
                </Loader>
            </>}
        </Fragment >);
}
export default BasicAuditList;
