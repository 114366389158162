/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect Mobile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * This type describes an interval in time, from a start date to a date specified by the start date shifted by the frequency period.
 * @export
 * @interface TimeFrameFrequency
 */
export interface TimeFrameFrequency {
    /**
     * 
     * @type {string}
     * @memberof TimeFrameFrequency
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof TimeFrameFrequency
     */
    type: TimeFrameFrequencyTypeEnum;
}


/**
 * @export
 */
export const TimeFrameFrequencyTypeEnum = {
    TimeFrameFrequency: 'time_frame_frequency'
} as const;
export type TimeFrameFrequencyTypeEnum = typeof TimeFrameFrequencyTypeEnum[keyof typeof TimeFrameFrequencyTypeEnum];


/**
 * Check if a given object implements the TimeFrameFrequency interface.
 */
export function instanceOfTimeFrameFrequency(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function TimeFrameFrequencyFromJSON(json: any): TimeFrameFrequency {
    return TimeFrameFrequencyFromJSONTyped(json, false);
}

export function TimeFrameFrequencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeFrameFrequency {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': json['from'],
        'type': json['type'],
    };
}

export function TimeFrameFrequencyToJSON(value?: TimeFrameFrequency | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from,
        'type': value.type,
    };
}

