import React from 'react';
import { useIntl } from 'react-intl';

/**
 * Keep track of a collection of selected elements. Elements have to be mapped to unique keys.
 * When selecting and deselection elements, the order is kept. I you select A before B, then
 * the selection will be `[A, B]`, but not `[B, A]`.
 *
 * If you select an element that is already selected, it will be moved to the end of the selection
 * list.
 *
 * keyFn: A function that uniquely identifies the elements that you want to select / deselect.
 *        For example: `(obj: {id: string}) => { return id; }`.
 *
 * */
export function useSelection<T>(keyFn: (element: T) => string): {
    selection: T[];
    resetSelection: () => void;
    selectElement: (element: T) => void;
    deselectElement: (element: T) => void;
    isElementSelected: (element: T) => boolean;
} {
    // This is here only for performance optimization of 'isElementSelected',
    // so we do not need to spend O(n) time searching the 'selection'.
    const indexRef = React.useRef({});

    const useState1 = React.useState([]);
    // es lint will not let me 'let' setSelection.
    // We assign 'selection', in case selectElement or deselectElement are called multiple times in the same react render cycle.
    // Otherwise we would use stale state, and undo the previous actions from the same render cycle.
    let selection = useState1[0];
    const setSelection = useState1[1]

    function selectElement(element: T): void {
        selection = selectionWithoutElement(element).concat(element);
        indexRef.current[keyFn(element)] = true;
        setSelection(selection);
    }

    function deselectElement(element: T): void {
        selection = selectionWithoutElement(element);
        indexRef.current[keyFn(element)] = false;
        setSelection(selection);
    }

    function resetSelection(): void {
        setSelection([]);
        indexRef.current = {};
    }

    function selectionWithoutElement(element: T): T[] {
        return selection.filter((i) => keyFn(i) != keyFn(element));
    }

    function isElementSelected(element: T): boolean {
        return !!indexRef.current[keyFn(element)];
    }

    return {
        selection,
        selectElement,
        deselectElement,
        resetSelection,
        isElementSelected,
    }
}

export const useTranslation = (key: string) => {

    const intl = useIntl();
    return intl.formatMessage({ id: key });
}
