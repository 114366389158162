import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { Attachment, FileResource, FileResourceTypeEnum, ReportCheckpoint } from '../../../../../backend_api/models';
import { getLocaleSpecificString, isUndefinedOrNullOrEmptyString, twMerge } from '../../../../../base/utils';
import FileItem from '../../../../attachments/components/FileItem';
import { Checkpoint } from '../../../../checklists/types';
import { TextItem } from '../../../../globals/components';
import { InfoIcon } from '../../../../globals/components/Utils';

type Props = {
    className?: string;
    checkpoint: Checkpoint | ReportCheckpoint;
};

const CheckpointInfo = (props: Props): React.ReactElement => {
    const { className, checkpoint } = props;
    const intl = useIntl();
    const hasInstructionFiles = checkpoint.instruction_files.length > 0;
    const instructionDetails = getLocaleSpecificString(checkpoint.instruction_details)
    const hasDetails = checkpoint.instruction_details && !isUndefinedOrNullOrEmptyString(instructionDetails);
    const hasInfo = hasInstructionFiles || hasDetails;
    const w = 'min-w-[' + (hasInstructionFiles ? 400 : 250) + 'px]';

    const getFileItem = (att: Attachment): FileResource => {
        if (att.type === 'image') {
            return { type: FileResourceTypeEnum.Image, preview_url: att.url, image: { image_url: att.url, mime_type: att.mimetype, size: att.size } }
        }
        if (att.type === 'file') {
            return { type: FileResourceTypeEnum.File, name: att.name, file: { download_url: att.url, mime_type: att.mimetype, size: att.size } }
        }
    };

    const images = <div className='flex py-0 mb-1'>{hasInstructionFiles && checkpoint.instruction_files.filter((f) => f.type === 'image').map((att) => {
        return <FileItem showDelete={false} key={'fi_' + att.url} file={getFileItem(att)} />
        /* return <FileItem openImageInLightBox={false} showDelete={false} key={'fi_' + att.url} file={getFileItem(att)} /> */
    })}</div>;

    const files = <div className='flex py-0 mb-1'>{hasInstructionFiles && checkpoint.instruction_files.filter((f) => f.type === 'file').map((att) => {
        return <FileItem showDelete={false} key={'fi_' + att.url} file={getFileItem(att)} />
    })}</div>;

    return hasInfo && <Popup
        closeOnTriggerClick
        closeOnPortalMouseLeave
        className={twMerge('w-[500px]', className)}
        trigger={<span className='pl-1' ><InfoIcon basic={true} /></span>}
    >
        <div className={w}>
            <h3><FormattedMessage id='checkpoint_info.header' /></h3>
            {hasInstructionFiles && <div>
                <TextItem column label={intl.formatMessage({ id: 'checkpoint_info.attachments' })} text={
                    <>{images}{files}</>
                } />
            </div>}
            {instructionDetails && <div>
                <TextItem column label={intl.formatMessage({ id: 'checkpoint_info.instruction_details' })}
                    text={instructionDetails} />
            </div>}
        </div>
    </Popup>
}
export default CheckpointInfo;
